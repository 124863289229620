import React, { Component } from "react"
import LayoutComponent from "../components/layout.component"
import SignUpProgress from "../components/sign-up-progress"
import { navigate } from "gatsby"
import HeaderComponent from "../components/header.component"

const axios = require("axios")

class InviteTeamsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountId: null,
      loginToken: null,
      emails: [],
      error: null,
    }

    this.handleTeamMemberInputChange = this.handleTeamMemberInputChange.bind(
      this
    )
    this.addAnother = this.addAnother.bind(this)
    this.sendInvitations = this.sendInvitations.bind(this)
    this.goToWebsite = this.goToWebsite.bind(this)
  }

  handleTeamMemberInputChange(event, index, prop) {
    const value = event.target.value
    const emails = this.state.emails
    emails[index][prop] = value

    this.setState({
      emails: emails,
    })
  }

  componentDidMount() {
    const sstat = sessionStorage.getItem("wdx_signup")
    if (sstat) {
      const ostat = JSON.parse(sstat)
      if (!ostat.accountId) {
        navigate("sign-up")
        return
      }

      this.setState({
        accountId: ostat.accountId,
        loginToken: ostat.loginToken,
      })
    } else {
      navigate("sign-up")
      return
    }

    const emails = [...Array(4).keys()].map(e => {
      return { email: "", name: "" }
    })

    this.setState({
      emails: emails,
    })
  }

  addAnother(e) {
    e.preventDefault()
    const emails = this.state.emails
    emails.push({ email: "", name: "" })
    this.setState({
      emails: emails,
    })
  }

  sendInvitations(e) {
    if (e) {
      e.preventDefault()
    }

    this.setState({
      error: null,
    })

    let serverUrl = process.env.API_URL + "user/inviteTeam"
    axios
      .post(serverUrl + "/", {
        accountId: this.state.accountId,
        team: this.state.emails,
      })
      .then(response => {
        this.goToWebsite(null)
      })
      .catch(error => {
        let err = null
        if (error && error.response && error.response.data) {
          err = error.response.data
        } else {
          err = "There is an error occured"
        }

        this.setState({
          error: err,
        })
      })
  }

  goToWebsite(e) {
    if (e) e.preventDefault()
    window.location.href =
      process.env.MAIN_SITE_URL + "Login.aspx?ltoken=" + this.state.loginToken
  }

  render() {
    const emailsRender = this.state.emails.map((email, i) => (
      <div className="row font-14 mb-3 mb-sm-0" key={"email_" + i}>
        <div className="col-sm px-2">
          <div className="form-group mb-2">
            <input
              type="email"
              className="form-control"
              value={email.email}
              onChange={e => this.handleTeamMemberInputChange(e, i, "email")}
            />
          </div>
        </div>
        <div className="col-sm px-2">
          <div className="form-group mb-2">
            <input
              type="text"
              className="form-control"
              value={email.name}
              onChange={e => this.handleTeamMemberInputChange(e, i, "name")}
            />
          </div>
          <div />
        </div>
      </div>
    ))

    return (
      <LayoutComponent>
        {/*FIXME:*/}
        {/*<SeoComponent title="Invite Teams"/>*/}
        <div className="container mb-100">
          <HeaderComponent theme="light" showMenu="false" />
          <div className="text-center my-5">
            <h2 className="text-primary mb-3">Invite your team to join WDX </h2>
          </div>
          <div className="mb-5">
            <SignUpProgress activeStep={2} />
          </div>
          <section>
            <form className="invite-teams-form mx-auto mb-4">
              <div className="row font-14 mb-3 mb-sm-0">
                <div className="col-sm px-2">
                  <div className="form-group mb-2">
                    <span className="text-secondary" for="">
                      Email Address
                    </span>
                  </div>
                </div>
                <div className="col-sm px-2">
                  <div className="form-group mb-2">
                    <span className="text-secondary" for="">
                      Name (optional)
                    </span>
                  </div>
                  <div />
                </div>
              </div>
              {emailsRender}
              <div className="font-14 ml-n3">
                <a href="/#" onClick={e => this.addAnother(e)}>
                  Add another
                </a>
              </div>
            </form>
            <div className="text-center invite-teams-btn">
              {this.state.error && (
                <div className="my-2 text-red">{this.state.error}</div>
              )}
              <button
                form="sign-up-form"
                type="submit"
                className="btn btn-info mb-4"
                onClick={e => this.sendInvitations(e)}
              >
                Send invitations
              </button>
              <div>
                <a
                  className="text-secondary"
                  href="/#"
                  onClick={e => this.goToWebsite(e)}
                >
                  Skip this step
                </a>
              </div>
            </div>
          </section>
        </div>
      </LayoutComponent>
    )
  }
}

export default InviteTeamsComponent
